import Button from "./components/Button";
import {
  ArrowRightIcon,
  HamburgerMenuIcon,
  Pencil1Icon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { CartIcon } from "./components/CartIcon";
import { AppIcon } from "./components/AppIcon";
import designStudioWoolit from "./assets/design_studio_woolit.png";
import designStudioWoolitMobile from "./assets/woolit_design_studio_mobile.png";
import intro from "./assets/intro.png";
import maskGroup from "./assets/Mask group.png";
import designStuidoIllustration from "./assets/design_studio_illustration.png";
import woolitShop from "./assets/woolit_shop.png";
import woolitApp from "./assets/woolit_app.png";
import Logo from "./components/Logo";
import HamburgerMenu from "./components/HamburgerMenu";
import { useRegisterMutation } from "./store/services/register";

type CardProps = {
  title: string;
  underTitle: string;
  paragraph: string;
  image: any;
  color?: string;
  icon: React.ReactNode;
};
const WoolitCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  title,
  underTitle,
  paragraph,
  image,
  color,
  icon,
}) => {
  const [showCard, setShowCard] = useState(true);
  return (
    <div
      className={cn(`flex grow flex-col  px-6 pb-6 md:flex-1 ${color}`, {
        "pb-0 md:min-h-[500px] md:pb-6 ": showCard,
        "min-h-[500px] pb-6 ": !showCard,
      })}
    >
      <div
        className=" flex flex-row items-center justify-between py-5 hover:cursor-pointer"
        onClick={() => setShowCard(!showCard)}
      >
        <div className="flex flex-row items-start  gap-4">
          <div className="w-5">{icon}</div>
          <span className="text-black">{title}</span>
        </div>
        <div className="items-center self-start p-1 md:hidden">
          {showCard ? <TriangleDownIcon /> : <TriangleUpIcon />}
        </div>
      </div>

      <div
        className={cn("relative flex  w-full grow justify-center", {
          "hidden md:flex ": showCard,
        })}
      >
        <img
          alt="Bilde av prosjekt, strikker og app"
          src={image}
          className="object-contain"
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
        />
      </div>
      <h2
        className={cn("text-xl", {
          "hidden md:block ": showCard,
        })}
      >
        {underTitle}
      </h2>
      <p
        className={cn("mt-2 self-end text-sm text-black", {
          "hidden md:block ": showCard,
        })}
      >
        {paragraph}
      </p>
    </div>
  );
};
const NavBar = () => {
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);

  return (
    <>
      <HamburgerMenu
        open={sidebarMenuOpen}
        onClose={() => setSidebarMenuOpen(false)}
      />

      <div
        className="
    mx-auto grid w-full max-w-screen-2xl grid-cols-3 items-center justify-between pt-2 px-4 md:px-6"
      >
        <div className=" items-center md:ml-10 gap-x-5 justify-self-start flex">
          <button
            onClick={() => setSidebarMenuOpen(true)}
            className="m-2 md:hidden"
          >
            <HamburgerMenuIcon height={25} width={25} />
          </button>
          <a
            href="https://shop.woolit.no"
            className="hidden cursor-pointer text-lg md:block"
          >
            Designs
          </a>
          <a
            href="https://shop.woolit.no/product/yarn"
            className="hidden cursor-pointer text-lg md:block"
          >
            Garn
          </a>
          <a
            href="https://shop.woolit.no/product/needle"
            className="hidden cursor-pointer text-lg md:block whitespace-nowrap"
          >
            Stricknadeln
          </a>
        </div>
        <div className="justify-self-center">
          <a href="https://shop.woolit.no">
            <Logo />
          </a>
        </div>
        <div className="md:mr-10 justify-self-end">
          <a
            className="flex  items-center gap-2 text-lg bg-landingsite-green rounded p-1 px-2"
            href="https://woolit.no"
          >
            Woolit.no <ArrowRightIcon height={20} width={20} />
          </a>
        </div>
      </div>
    </>
  );
};

function App() {
  const [registerValueTop, setRegisterValueTop] = useState("");
  const [registerValueBottom, setRegisterValueBottom] = useState("");

  const [register, { isLoading, isSuccess, isError, status }] =
    useRegisterMutation();

  const handleSubmitTop = (e: any) => {
    e.preventDefault();
    register({ email: registerValueTop, source: "woolit.de" }).then(() => {
      setRegisterValueTop("");
    });
  };

  const handleSubmitBottom = (e: any) => {
    e.preventDefault();
    register({ email: registerValueBottom, source: "woolit.de" }).then(() => {
      setRegisterValueBottom("");
    });
  };

  return (
    <div>
      <NavBar />
      <div className="mt-14  flex flex-col gap-6">
        {/* Intro */}
        <div className="grid-rows-12 relative grid w-full grid-cols-1 gap-2 overflow-hidden bg-white md:min-h-[500px] md:grid-cols-6 ">
          <div className=" col-span-1 row-span-4 flex max-w-3xl flex-col gap-4 p-10 md:col-span-4 md:row-span-5 md:ml-20 md:p-20 ">
            <span className=" text-sm text-light-brown">
              Woolit International
            </span>
            <h1 className=" text-3xl text-black">
              Entwerfe, veröffentliche und verkaufe dein Stickmotive.{" "}
            </h1>
            <span className=" text-black ">
              Woolit ist ein offenes Strick-Ökosystem mit Sitz in Norwegen, das
              sich jetzt auch für Strickdesigner aus den USA und Deutschland
              öffnet.
              <b>
                Trag dich in die Warteliste für einen frühen Zugang zum
                Woolit-Design-Studio ein!
              </b>
            </span>
            <form
              onSubmit={(e) => {
                handleSubmitTop(e);
              }}
              className="mt-4 hidden grid-cols-8 gap-4  md:grid"
            >
              <div className="col-span-4">
                <input
                  value={registerValueTop}
                  onChange={(e) => setRegisterValueTop(e.target.value)}
                  placeholder="Deine E-Mail"
                  className="col-span-4 w-full h-12 rounded border bg-whiteish p-2"
                />
                {isError && (
                  <p className="text-par-s-reg text-negative p-1">
                    Ein Fehler ist aufgetreten.
                  </p>
                )}
                {isSuccess && status === "fulfilled" && (
                  <p className="text-par-s-reg text-positive p-1">
                    Du bist jetzt auf der Warteliste!
                  </p>
                )}
              </div>
              <Button
                className="col-span-3 col-start-5 h-12"
                loading={isLoading}
              >
                Auf die Warteliste setzen
              </Button>
            </form>
          </div>

          <div className="relative col-start-1 row-span-5 row-start-5 pl-20 md:col-span-2 md:col-start-5 md:row-span-3 md:row-start-1 md:pl-0 ">
            <img
              alt="Bilde av en strikker"
              src={intro}
              className="object-contain md:absolute md:top-20 md:left-0"
              width={600}
              height={380}
            />
          </div>
          <form
            onSubmit={(e) => {
              handleSubmitTop(e);
            }}
            className="row-start-12 mt-4 grid grid-cols-8 gap-4 px-10 md:hidden"
          >
            <div className="col-span-5">
              <input
                value={registerValueTop}
                onChange={(e) => setRegisterValueTop(e.target.value)}
                placeholder="Your e-mail"
                className=" h-11 w-full rounded border bg-whiteish p-2"
              />
              {isError && (
                <p className="text-par-s-reg text-negative p-1">
                  Ein Fehler ist aufgetreten.
                </p>
              )}
              {isSuccess && status === "fulfilled" && (
                <p className="text-par-s-reg text-positive p-1">
                  Du bist jetzt auf der Warteliste!
                </p>
              )}
            </div>

            <Button className="col-span-2 col-start-6 h-11 " variant="primary">
              Beitreten
            </Button>
          </form>
        </div>

        <div className="z-10 flex w-full flex-col  md:min-h-[500px] md:flex-row">
          {/* Woolit design studio */}

          <WoolitCard
            title="Woolit Design Studio"
            underTitle="Entwerfe im Woolit-Design -Studio"
            paragraph="Entwerfe deine digitalen Muster im Woolit-Design-Studio, durch das alle Muster mit automatischen Größenangaben und Garnlieferanten integriert werden!"
            image={designStuidoIllustration}
            color="bg-whiteish"
            icon={<Pencil1Icon height={22} width={22} />}
          />

          {/* Woolit shop */}
          <WoolitCard
            title="Woolit Shop"
            underTitle="Verkaufe deine Entwürfe als fertig zu strickende Garnpakete und Muster!"
            paragraph="Strickmuster, die du veröffentlichst, sind dann in verschiedenen Formen erhältlich. Fertige Strickstücke, die von den lokalen Woolit-Strickerinnen hergestellt werden, Garnpakete und digitale Muster."
            image={woolitShop}
            color="bg-beige"
            icon={<CartIcon />}
          />

          {/* Woolit App */}
          <WoolitCard
            title="Woolit App"
            underTitle="Zugang zu digitalen Mustern in der App"
            paragraph="Alle von dir veröffentlichten Muster sind in der Woolit-App zum Lesen und Kaufen verfügbar. Das schafft nicht nur Benutzerfreundlichkeit, sondern enthält auch nützliche Tools wie den Strickrechner!"
            image={woolitApp}
            color="bg-orange-500"
            icon={<AppIcon />}
          />
        </div>

        {/* Woolit Design Studio */}
        <div className="relative flex w-full flex-col items-center md:mb-20 md:justify-start">
          <div className="mt-10 flex max-w-3xl flex-col pl-10 pr-5 md:items-center md:pr-10">
            <span className="w-fit text-sm text-light-brown md:text-lg">
              Veröffentlichung 2023
            </span>
            <h1 className="text-2xl text-black">Woolit Design Studio</h1>
            <p className="mt-4 text-black md:text-center">
              Entwerfe und veröffentliche dein Strickmuster mit
              Woolit-Design-Studio. Die mit diesem Programm entworfenen Muster
              sind in Garnpaketen und der Woolit-App für digitale Strickmuster
              integriert. Mache aus deinem Hobby eine Karriere!
            </p>
          </div>
          <div className="relative  min-h-[300px] w-full  md:min-h-[550px]">
            <img
              alt="Bilde av design studio"
              src={designStudioWoolit}
              className="hidden object-contain md:block "
              width={1920}
              height={1080}
            />
            <img
              alt="Bilde av design studio"
              src={designStudioWoolitMobile}
              className="block object-contain md:hidden "
              width={1920}
              height={1080}
            />
          </div>
        </div>

        {/* Main site */}
        <div className="relative flex min-h-[700px] w-full justify-center bg-cover bg-center bg-no-repeat bg-main-site">
          <div className="p5 absolute top-20 z-10 flex flex-col justify-center md:items-center md:p-20">
            <a
              className="ml-10 flex flex-row items-center gap-4 p-2 text-white md:justify-center"
              href="https://woolit.no"
            >
              <div className="h-7 w-7">
                <img
                  alt="Bilde av Norges flagg"
                  src={maskGroup}
                  width={30}
                  height={30}
                  className=" object-contain"
                />
              </div>
              <h1 className="text-2xl text-white md:text-3xl">
                Zur Hauptseite
              </h1>
              <ArrowRightIcon height={40} width={40} />
            </a>
            <div className="flex max-w-[50rem] flex-col items-center">
              <p className="mx-10 my-5 mt-5 text-white md:text-center">
                In Skandinavien bietet Woolit viele verschiedene
                Dienstleistungen und Produkte an, die über Strickdesign
                hinausgehen. Wir arbeiten intensiv daran, dieses Angebot
                international zu erweitern, aber natürlich bist du schon jetzt
                herzlich eingeladen, einen Blick darauf zu werfen!{" "}
              </p>
              <span className="text-white">- oder -</span>
            </div>
            {/* Desktop */}
            <form
              onSubmit={(e) => {
                handleSubmitBottom(e);
              }}
              className=" mt-4 hidden max-w-2xl grid-cols-8 gap-4 px-10 md:grid"
            >
              <div className="col-span-5">
                <input
                  value={registerValueBottom}
                  onChange={(e) => setRegisterValueBottom(e.target.value)}
                  placeholder="Schreibe uns eine E-Mail, um Strickdesigner zu werden!"
                  className="col-span-5 h-11 w-full rounded border bg-whiteish p-2"
                />
                {isError && (
                  <p className="text-par-s-reg text-negative p-1">
                    Ein Fehler ist aufgetreten.
                  </p>
                )}
                {isSuccess && status === "fulfilled" && (
                  <p className="text-par-s-reg text-whiteish p-1">
                    Du bist jetzt auf der Warteliste!
                  </p>
                )}
              </div>

              <Button
                className="col-span-3 col-start-6 h-11 bg-black "
                variant="primary"
              >
                Auf Warteliste setzen
              </Button>
            </form>
            {/* Mobile */}
            <form
              onSubmit={(e) => {
                handleSubmitBottom(e);
              }}
              className="mt-4 grid max-w-2xl grid-rows-2 gap-4 px-10 md:hidden"
            >
              <div className="col-span-1">
                <input
                  value={registerValueBottom}
                  onChange={(e) => setRegisterValueBottom(e.target.value)}
                  placeholder="Schreibe uns eine E-Mail, um Strickdesigner zu werden!"
                  className="col-span-1 h-11 w-full rounded border bg-whiteish p-2"
                />
                {isError && (
                  <p className="text-par-s-reg text-negative p-1">
                    Ein Fehler ist aufgetreten.
                  </p>
                )}
                {isSuccess && status === "fulfilled" && (
                  <p className="text-par-s-reg text-whiteish p-1">
                    Du bist jetzt auf der Warteliste!
                  </p>
                )}
              </div>
              <Button className="col-span-1  h-11 " variant="primary">
                Beitreten
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
